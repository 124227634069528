@import './variables.scss';
@import './components.scss';

html,
body {
  background: #f4f4f4; // tried setting the background color in both bootstrap and ant theme file.
}

#root {
  height: 100%;
  background: #f4f4f4; // tried setting the background color in both bootstrap and ant theme file.
}

body {
  height: 100%;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Open Sans', sans-serif !important;
}

.use-pointer {
  cursor: pointer;
}
.pointer {
  cursor: pointer;
}

.no-pointer-events {
  pointer-events: none;
}

.no-select {
  user-select: none;
}

.p-small {
  font-size: 0.8rem;
}

.p-mini {
  font-size: 0.7rem;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome and Opera */
}

/*
@include media-breakpoint-down(md) {
  html {
    font-size: 0.9rem;

    p {
      font-size: 0.9rem;
    }
  }
}
*/

.fading-dot {
  //border: 2px solid red;
  opacity: 1;
  //border-radius: 50%;
  animation: attention-dot 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

@keyframes fading-dot {
  0% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.headline {
  font-family: Lora;
}

.white-block {
  background: white;
  box-shadow: 0px 4px 50px -2px rgba(0, 0, 0, 0.05);
}

.back-image {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
}
